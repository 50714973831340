import React, { useRef } from 'react';
import PortableText from 'react-portable-text';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	Grid,
	Typography,
	Card,
	CardContent,
	CardHeader,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.workwaveBlue,

		padding: '0px',
		borderRadius: '6px',
		// maxHeight: '95%',
		position: 'relative',
		// paddingTop: '2.75rem',
		[theme.breakpoints.down('md')]: {
			height: '400px',
			// maxWidth: '100%',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	height: '325px',
		// },
		[theme.breakpoints.down('xs')]: {
			height: '600px',
		},

		transition: 'transform 0.25s',
		// boxShadow:
		// 	'0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0)',
	},
	title: {
		fontSize: '1.4rem',
		fontWeight: 'bold',
		textAlign: 'center',
		margin: '1rem 0 0.5rem 0',
		color: '#002D5C',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.3rem',
		},
	},
	pos: {
		textAlign: 'center',
		color: '#4B5B69',
	},
	content: {
		lineHeight: 1.8,
		height: '100%',
		// [theme.breakpoints.down('lg')]: {
		// 	height: '105px',
		// },
		[theme.breakpoints.down('md')]: { lineHeight: 1.2 },
		// [theme.breakpoints.down('md')]: { height: '180px' },
	},
	button: {
		padding: '6px 20px',
		opacity: 1,
		fontSize: '1.1rem',
		transition: 'all 0.35s ease !important',
		'&:hover': {
			opacity: '0.85 !important',
		},
	},
}));

export const SecurityCard = ({ card, index, length }) => {
	const classes = useStyles();
	const bodyRef = useRef(null);

	const med = index === length - 1 && length % 2 !== 0 ? 12 : 6;
	const medium = useMediaQuery('(max-width: 960px)');
	const lg = useMediaQuery('(max-width: 1280px)');

	return (
		<Grid item xs={12} md={med}>
			<Card
				className={classes.root}
				elevation={3}
				style={{
					height:
						(index === length - 1 && length % 2 !== 0) || medium
							? `${bodyRef.current?.clientHeight + 50}px`
							: lg
							? '425px'
							: '350px',
				}}>
				<CardHeader
					style={{
						background: card.cardAccentColor[0]?.hexValue ?? '#002D5C',
						padding: '8px',
						width: '100%',
					}}
				/>
				<CardContent
					ref={bodyRef}
					style={
						{
							// height: '100%',
							// display: 'flex',
							// flexDirection: 'column',
							// alignItems: 'center',
							// justifyContent: 'space-between',
						}
					}>
					<Grid
						container
						direction='row'
						alignItems='space-between'
						spacing={2}
						item
						xs={12}>
						<Grid
							container
							item
							style={{ flexWrap: 'nowrap' }}
							direction='row'
							justifyContent='flex-start'
							alignItems='center'>
							<FontAwesomeIcon
								icon={['fad', card.faIcon]}
								style={{
									color: card.cardAccentColor[0]?.hexValue ?? '#002D5C',
									height: '45px',
									width: '45px',
									border: `1px solid #E5EAF4`,
									borderRadius: '8px',
									background: '#E5EAF4',
									padding: '6px',
									textAlign: 'center',
									marginRight: '1rem',
								}}
							/>
							<PortableText
								content={card._rawTitle}
								serializers={{
									h4: ({ children }) => (
										<Typography
											variant='h6'
											style={{ fontWeight: 550, lineHeight: 1.3 }}>
											{children}
										</Typography>
									),
								}}
							/>
						</Grid>
						<Grid
							container
							direction='row'
							alignItems='flex-start'
							item
							className={classes.content}>
							<Typography variant='body1'>
								<PortableText
									content={card._rawBody}
									serializers={{
										link: (props) => {
											return (
												<a
													href={props.href}
													target='_blank'
													rel='noreferrer'
													style={{ color: '#4B5B69' }}>
													{props.children[0]}
												</a>
											);
										},
										internalLink: (props) => {
											return (
												<Link
													to={props.internalSlug}
													style={{
														color: '#4B5B69',
														textDecoration: 'underline',
													}}>
													{props.children}
												</Link>
											);
										},
									}}
								/>
							</Typography>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Grid>
	);
};
