import React from 'react';
import PortableText from 'react-portable-text';

import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SecurityCard } from './SecurityCard';

const useStyles = makeStyles(
	(theme) => ({
		text: {
			color: '#4B5B69',
			fontFamily: 'Roboto',
			'& a': {
				color: theme.workwaveBlue,
			},
		},
	}),
	{ index: 1 }
);

export const SecuritySection = ({ header, body, cards }) => {
	const classes = useStyles();
	const length = cards.length;
	return (
		<div style={{ paddingBottom: '4rem' }}>
			<div>
				<Typography variant='h4' color='primary' style={{ fontWeight: 700 }}>
					{header}
				</Typography>
				<Typography className={classes.text}>
					<PortableText
						content={body}
						serializers={{
							emailPhoneLink: (props) => {
								return <a href={props.emailPhoneHref}>{props.children[0]}</a>;
							},
						}}
					/>
				</Typography>
			</div>

			<Grid container direction='row' spacing={4}>
				{cards.map((card, index) => (
					<SecurityCard key={index} card={card} length={length} index={index} />
				))}
			</Grid>
		</div>
	);
};
