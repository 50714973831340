import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';

import { Container, Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../components/General/Hero';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { SecuritySection } from '../components/General/Security/SecuritySection';
import { SEO } from '../components/SEO';
import FormModalContext from '../context/FormModalContext';

// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		fontFamily: 'Roboto',
		lineHeight: 1.8,
		'& a': {
			color: theme.workwaveBlue,
		},
	},
  curveTop: {
    marginTop: '14rem',
    [theme.breakpoints.down('xl')]: {
			marginTop: '16rem',
		},
    [theme.breakpoints.down('lg')]: {
			marginTop: '12rem',
		},
    [theme.breakpoints.down('md')]: {
			marginTop: '10rem',
		},
    [theme.breakpoints.down('xs')]: {
			marginTop: '8rem',
		},
  },
}));

const SecurityPage = ({ data, location }) => {
	const security = data.security.edges[0].node;
	const classes = useStyles();

	const { formModalOpen } = useContext(FormModalContext);

	const {
		hero,
		introBody,
		operationalHeader,
		_rawOperationalBody,
		operationalCards,
		technologyHeader,
		_rawTechnologyBody,
		technologyCards,
		dataHeader,
		_rawDataBody,
		dataCards,
		privacyHeader,
		_rawPrivacyBody,
		privacyCards,
		employeeHeader,
		_rawEmployeeBody,
		employeeCards,
		reportHeader,
		_rawReportBody,
		metaDescription,
		metaTitle,
	} = security;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />

			<Hero hero={hero} />
			<Container style={{ marginBottom: '4rem' }}>
				<Grid
					container
					spacing={4}
					style={{ paddingBottom: '2rem' }}
					direction='row'
					justifyContent='center'
					alignItems='center'>
					<Grid item>
						<Typography
							variant='body1'
							style={{
								paddingBottom: '2rem',
								lineHeight: 1.8,
							}}>
							{introBody}
						</Typography>
					</Grid>
				</Grid>
				<SecuritySection
					header={operationalHeader}
					body={_rawOperationalBody}
					cards={operationalCards}
				/>
				<SecuritySection
					header={technologyHeader}
					body={_rawTechnologyBody}
					cards={technologyCards}
				/>
				<SecuritySection
					header={dataHeader}
					body={_rawDataBody}
					cards={dataCards}
				/>
				<SecuritySection
					header={privacyHeader}
					body={_rawPrivacyBody}
					cards={privacyCards}
				/>
				<SecuritySection
					header={employeeHeader}
					body={_rawEmployeeBody}
					cards={employeeCards}
				/>
				<Grid item>
					<Typography
						variant='h4'
						color='primary'
						style={{ fontWeight: 700, paddingTop: '4rem' }}>
						{reportHeader}
					</Typography>
					<Typography className={classes.text}>
						<PortableText
							content={_rawReportBody}
							serializers={{
								emailPhoneLink: (props) => {
									return <a href={props.emailPhoneHref}>{props.children[0]}</a>;
								},
								buttonLink: (props) => {
									return (
										<a href={props.buttonSlug} target='_blank'>
											<Button variant='contained' color='primary'>
												{props.children[0]}
											</Button>
										</a>
									);
								},
							}}
						/>
					</Typography>
				</Grid>
				{formModalOpen ? (
					<Form
						privacy
						noForm
						location={location}
						pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					/>
				) : null}
			</Container>
			<div className={classes.curveTop}>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query SecurityPageQuery {
		security: allSanitySecurity {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					introBody
					operationalHeader
					_rawOperationalBody
					operationalCards {
						faIcon
						cardAccentColor {
							hexValue
						}
						_rawTitle
						_rawBody
					}
					technologyHeader
					_rawTechnologyBody
					technologyCards {
						faIcon
						cardAccentColor {
							hexValue
						}
						_rawTitle
						_rawBody
					}
					dataHeader
					_rawDataBody
					dataCards {
						faIcon
						cardAccentColor {
							hexValue
						}
						_rawTitle
						_rawBody
					}
					privacyHeader
					_rawPrivacyBody
					privacyCards {
						faIcon
						cardAccentColor {
							hexValue
						}
						_rawTitle
						_rawBody
					}
					employeeHeader
					_rawEmployeeBody
					employeeCards {
						faIcon
						cardAccentColor {
							hexValue
						}
						_rawTitle
						_rawBody
					}
					reportHeader
					_rawReportBody
				}
			}
		}
	}
`;

export default SecurityPage;
